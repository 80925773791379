import { headerItemFlex } from '@rsa-digital/evo-shared-components/components/Header/Header/styles';
import LogoLink from '@rsa-digital/evo-shared-components/components/LogoLink';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import styled, { css } from 'styled-components';

const flexGapProperties = css`
  display: flex;
  gap: 25px;
  flex-direction: row;
  align-items: flex-end;
`;

const mediaQueryGap = mediaQuery.tabletPortrait`
gap: 15px;
`;

export const StyledHeaderLogos = styled('div')`
  width: 100%;
  height: 100%;
  ${flexGapProperties}
  padding: ${spacing(2)} 0;
`;

export const LogoDividerWrapper = styled('div')`
  height: 95%;
  ${flexGapProperties}
  ${mediaQueryGap}
`;

export const StyledDivider = styled('div')`
  display: flex;
  height: 100%;
  ${mediaQuery.tabletPortrait`
     align-items: flex-end;
     height: 48px;
    `}
  width: 2px;
  background-color: ${colors.neutral05};
`;

export const Logo = styled(LogoLink)`
  flex: ${headerItemFlex};
  align-items: center;
  ${mediaQuery.tabletLandscape`
     display: flex;
    `}
  img {
    max-height: 48px;
  }
`;

export const LogoWrapper = styled.div`
  ${flexGapProperties}
  margin: 0 0;
  ${mediaQueryGap}
`;
