import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import React from 'react';
import { trackDropdownSelect } from 'helpers/eventTracking';
import { DropdownContainer, StyledDropdown } from './styles';
import { SegmentContainer } from '../../styles';
import { SelectorProps } from '../../types';
import {
  StyledHeading,
  StyledSecondarySubheading,
  StyledSubheading,
  SubheadingContainer,
} from '../styles';

const DropdownSelector = ({
  heading,
  subheading,
  secondary_subheading,
  selector_unique_value,
  screen_reader_text,
  dropdownOptions,
  handleChange,
  value,
}: SelectorProps): JSX.Element => (
  <SegmentContainer hasTransparentBackground noBorder>
    <DropdownContainer key={selector_unique_value}>
      {heading && <StyledHeading>{heading}</StyledHeading>}
      <SubheadingContainer>
        {subheading && (
          <StyledSubheading
            marginTop={!heading ? spacing(2) : undefined}
            noSecondarySubheading={!secondary_subheading}>
            {subheading}
          </StyledSubheading>
        )}
        {secondary_subheading && (
          <StyledSecondarySubheading>{secondary_subheading}</StyledSecondarySubheading>
        )}
      </SubheadingContainer>
      <StyledDropdown
        id={selector_unique_value}
        options={dropdownOptions}
        ariaLabel={screen_reader_text ?? 'This is a dropdown field.'}
        value={value}
        data-testid={`dropdown_${selector_unique_value}`}
        data-cy={`DropdownSelector_${selector_unique_value}`}
        handleValue={(val: string) => {
          handleChange(val);
          trackDropdownSelect(val);
        }}
      />
    </DropdownContainer>
  </SegmentContainer>
);

export default DropdownSelector;
