import { Orientation } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import React from 'react';
import { trackRadioButtonClick } from 'helpers/eventTracking';
import {
  StyledRadioInput,
  StyledSecondarySelectorContainer,
  StyledSubheadingContainer,
} from './styles';
import { SelectorProps } from '../../types';
import { StyledHeading, StyledSecondarySubheading, StyledSubheading } from '../styles';

const SecondarySelector = ({
  heading,
  subheading,
  secondary_subheading,
  selector_unique_value,
  screen_reader_text,
  dropdownOptions,
  handleChange,
  value,
}: SelectorProps): JSX.Element => (
  <StyledSecondarySelectorContainer
    data-testid={`secondary-selector-${selector_unique_value}`}
    data-cy={`SecondarySelector-${selector_unique_value}`}>
    {heading && <StyledHeading>{heading}</StyledHeading>}
    <StyledSubheadingContainer>
      {subheading && (
        <StyledSubheading noSecondarySubheading={!secondary_subheading}>
          {subheading}
        </StyledSubheading>
      )}
      {secondary_subheading && (
        <StyledSecondarySubheading>{secondary_subheading}</StyledSecondarySubheading>
      )}
    </StyledSubheadingContainer>
    <StyledRadioInput
      id={selector_unique_value}
      key={selector_unique_value}
      value={value}
      options={dropdownOptions}
      orientationOverride={Orientation.mobileVertical}
      aria-label={screen_reader_text ?? 'This is a selector button.'}
      onChange={(e) => {
        handleChange(e.target.value);
        trackRadioButtonClick(heading ?? selector_unique_value, e.target.value);
      }}
    />
  </StyledSecondarySelectorContainer>
);

export default SecondarySelector;
