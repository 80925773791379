import { ImageOptionalLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import React from 'react';
import {
  Logo,
  LogoDividerWrapper,
  LogoWrapper,
  StyledDivider,
  StyledHeaderLogos,
} from './styles';

export type LogosContainerProps = {
  logoLinks: ImageOptionalLink[];
  shouldEagerLoad: boolean;
};

const LogosContainer: React.FC<LogosContainerProps> = ({
  logoLinks,
  shouldEagerLoad,
}) => (
  <StyledHeaderLogos>
    {logoLinks.map((logoLink: ImageOptionalLink, index: number) => (
      <LogoDividerWrapper key={`logo-${logoLink.link}`}>
        <LogoWrapper>
          <Logo
            logoLink={logoLink}
            shouldEagerLoad={shouldEagerLoad}
            data-testid="logo"
            data-cy="Logo"
          />
        </LogoWrapper>
        {index < logoLinks.length - 1 && <StyledDivider data-testid="divider" />}
      </LogoDividerWrapper>
    ))}
  </StyledHeaderLogos>
);

export default LogosContainer;
