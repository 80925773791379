import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import { colors } from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import styled from 'styled-components';

export const StyledGridContainer = styled(GridContainer)`
  width: 100vw;
  padding: 0;
  margin: 0;
  background-color: ${colors.neutral07};
`;
