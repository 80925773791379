import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import { colors } from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import styled from 'styled-components';

export const StyledHeader = styled(PageWidthContainer)`
  border-bottom: 2px solid ${colors.neutral05};
  background-color: ${colors.neutral08};
  max-height: 95px;
  padding: 0 calc((100vw - 100%) / 2);
`;
