import React from 'react';
import {
  DropdownSelector,
  SecondarySelector,
} from 'components/blocks/SegmentSelector/Selectors';
import {
  CsSegmentSelector,
  SelectorProps,
} from 'components/blocks/SegmentSelector/types';
import { nonFatalBuildError } from 'helpers/errorReporting';

// This is strictly used for segment selectors, since they require additional data from the usual blocks
type SelectorsMapping = {
  key: string;
  value: (props: SelectorProps) => JSX.Element;
}[];

const SELECTORS_MAPPING: SelectorsMapping = [
  {
    key: 'dropdown_segment_selector',
    value: DropdownSelector,
  },
  {
    key: 'optional_secondary_segment_selector',
    value: SecondarySelector,
  },
];

const mapSelector = (
  selectorsMapping: SelectorsMapping,
  data: CsSegmentSelector,
  inputDetails: {
    dropdownOptions: { name: string; value: string }[];
    value: string;
    handleChange: (value: string) => void;
  }
): JSX.Element | null => {
  const targetData = Object.entries(data).find(([, value]) => !!value);

  /* istanbul ignore if */
  if (!targetData) {
    return null;
  }

  const targetValues = targetData[1];
  if (targetValues === null) {
    return null;
  }

  const targetKey = targetData[0];

  const Selector = selectorsMapping.find(({ key }) => key === targetKey)?.value;

  /* istanbul ignore if */
  if (!Selector) {
    nonFatalBuildError(
      `Unrecognised segment selector of type '${targetKey}'.`,
      'Have you forgotten to update the mapping?'
    );
    return null;
  }

  return <Selector {...targetValues} {...inputDetails} />;
};

const mapSegmentSelector = (
  data: CsSegmentSelector,
  inputDetails: {
    dropdownOptions: { name: string; value: string }[];
    value: string;
    handleChange: (value: string) => void;
  }
): JSX.Element | null => mapSelector(SELECTORS_MAPPING, data, inputDetails);

export default mapSegmentSelector;
