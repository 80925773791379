import { graphql } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import mapBlocks from 'helpers/blockMappers/mapBlocks';
import mapSegmentSelector from 'helpers/segmentSelectorMappers/mapSegmentSelector';
import { extractSelectorData } from 'helpers/segmentSelectorMappers/mapSegmentSelectorsTree';
import { SegmentContainer } from './styles';
import { CsSegmentSelector } from './types';

/* 
      Since there will be more types of segment selectors at each level, creating a component for each type and importing them for further usage would lead to dependency cycles.
    The same result will happen at the separation of the segment contents component from the segment selectors.
      Hence, this will be the main component to render the segment selectors along with the segment contents.
*/

export type SegmentSelectorProps = {
  data: CsSegmentSelector;
  parentValue?: string;
};

const SegmentSelector: React.FC<SegmentSelectorProps> = ({ data, parentValue }) => {
  const internalData = extractSelectorData(data);

  const dropdownOptions = useMemo(
    () =>
      !internalData
        ? []
        : internalData.segment_contents.map((option) => ({
            name: option.name,
            value: option.value,
          })),
    [internalData]
  );

  const [value, setValue] = useState<string>('');

  const handleChange = (targetValue: string): void => {
    setValue(targetValue);
  };

  const selectedOptionData = useMemo(
    () =>
      !internalData
        ? null
        : internalData.segment_contents.find((option) => option.value === value),
    [value, internalData]
  );

  // For resetting the value of the children selectors when a parent value is changed
  useEffect(
    () => () => {
      setValue('');
    },
    [parentValue]
  );

  useEffect(() => {
    setValue('');
  }, [parentValue]);

  return (
    <>
      {mapSegmentSelector(data, {
        dropdownOptions,
        value,
        handleChange,
      })}
      {selectedOptionData && (
        <>
          <SegmentContainer
            hasGreyBackground={selectedOptionData.has_gray_background}
            noBorder={
              !!selectedOptionData.nextSelector?.optional_secondary_segment_selector
            }>
            {mapBlocks(selectedOptionData.blocks)}
            {!!selectedOptionData.nextSelector && (
              <SegmentSelector
                data={selectedOptionData.nextSelector}
                parentValue={value}
              />
            )}
          </SegmentContainer>
          <SegmentContainer>
            {mapBlocks(selectedOptionData.bottom_blocks)}
          </SegmentContainer>
        </>
      )}
    </>
  );
};

export default SegmentSelector;

export const query = graphql`
  fragment SegmentSelectorOption on cs__segment_selector_option {
    name
    value
    has_gray_background
    blocks {
      rich_text {
        ...RichTextBlock
      }
      accordion {
        ...AccordionBlockSegmentSelectorOption
      }
      action_cards {
        ...ActionCardsBlock
      }
      cta_banners {
        ...CtaBannersBlock
      }
    }
    bottom_blocks {
      rich_text {
        ...RichTextBlock
      }
      accordion {
        ...AccordionBottomBlockSegmentSelectorOption
      }
      action_cards {
        ...ActionCardsBlock
      }
      cta_banners {
        ...CtaBannersBlock
      }
    }
  }

  fragment DropdownSegmentSelectorBlock on cs__dropdown_segment_selector {
    selector_unique_value
    parent_identifier
    screen_reader_text
    heading
    subheading
    secondary_subheading
    segment_contents {
      ...SegmentSelectorOption
    }
  }

  fragment OptionalSecondarySegnentSelectorBlock on cs__optional_secondary_segment_selector {
    selector_unique_value
    parent_identifier
    screen_reader_text
    heading
    subheading
    secondary_subheading
    segment_contents {
      ...SegmentSelectorOption
    }
  }
`;
