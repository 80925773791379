import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, { Spacing } from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const SubheadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const StyledHeading = styled.h1`
  margin-top: 0;
  font-size: 3.25rem;
`;

export const StyledSubheading = styled.p<{
  noSecondarySubheading?: boolean;
  marginTop?: Spacing;
}>`
  margin-top: ${({ marginTop }) => marginTop ?? 0};
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: ${({ noSecondarySubheading }) =>
    noSecondarySubheading ? spacing(2) : spacing(1)};
`;

export const StyledSecondarySubheading = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin-top: ${spacing(1)};
  line-height: ${spacing(3)};
  white-space: pre-line;
  margin-bottom: ${spacing(3)};
`;

export const SelectorContainer = styled.div`
  width: 90vw;
  ${mediaQuery.tabletLandscape`
  width: 53vw;
  `}
  text-align: left;
  margin-bottom: 40px;
`;
