import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import React, { useEffect } from 'react';
import Analytics from 'components/Analytics';
import { reloadOneTrust } from 'components/Analytics/onetrust';
import FontTracking from 'components/FontTracking';
import { PLExitFooter } from 'components/Footer';
import MultipleLogosSimpleHeader from 'components/MultipleLogosSimpleHeader';
import PageMetadata, { MetaTags } from 'components/PageMetadata';
import Recaptcha from 'components/Recaptcha';
import RefreshButton from 'components/RefreshButton';
import { StyledGridContainer } from './styles';

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const showRefreshButton =
  process.env.GATSBY_HIDE_REFRESH_BUTTON !== 'true' &&
  (activeEnv === 'development' || activeEnv === 'preview');
const disableAnalytics = process.env.GATSBY_DISABLE_ANALYTICS === 'true';

type ExitPageLayoutProps = {
  pageMetadata: MetaTags;
  locale: string;
};

const ExitPageLayout: React.FC<ExitPageLayoutProps> = ({
  pageMetadata,
  locale,
  children,
}) => {
  useEffect(() => {
    if (!disableAnalytics) {
      reloadOneTrust();
    }
  }, []);

  return (
    <>
      {!disableAnalytics && <Analytics />}
      <Recaptcha />
      <FontTracking />
      <PageMetadata meta={pageMetadata} />
      <StyledGridContainer as="main">
        <GridContainer>
          <MultipleLogosSimpleHeader locale={locale} />
        </GridContainer>
        {showRefreshButton && /* istanbul ignore next */ <RefreshButton />}
        {children}
        <GridContainer>
          <PLExitFooter locale={locale} />
        </GridContainer>
      </StyledGridContainer>
    </>
  );
};

export default ExitPageLayout;
