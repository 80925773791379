import {
  ImageLink,
  NonTextLink,
} from '@rsa-digital/evo-shared-components/commonTypes/links';
import { graphql, useStaticQuery } from 'gatsby';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { handleAndTrackLinkClick } from 'helpers/navigation';
import { CsAsset } from 'types/contentStack';

type CsLogo = {
  image: CsAsset;
  link_screen_reader_text: string;
  url: string;
};

type CsMultipleLogosHeader = {
  locale: string;
  logos: CsLogo[];
};

type HeaderData = {
  allCsMultipleLogosSimpleHeader: { nodes: CsMultipleLogosHeader[] };
};

type MultipleLogosHeaderProps = {
  logoLinks: ImageLink[];
};

const headerQuery = graphql`
  query {
    allCsMultipleLogosSimpleHeader {
      nodes {
        locale
        logos {
          image {
            ...Asset
          }
          link_screen_reader_text
          url
        }
      }
    }
  }
`;

const mapLink = (logoData: CsLogo): NonTextLink => ({
  url: logoData.url,
  screenReaderText: logoData.link_screen_reader_text,
  onClick: (e) =>
    handleAndTrackLinkClick(
      logoData.url,
      'headerLogo',
      logoData.link_screen_reader_text
    )(e),
});

const processLogoLinks = (logosData: CsLogo[]): ImageLink[] =>
  logosData.map((logoData) => {
    const processedLogo = processImageAsset(logoData.image);
    if (!processedLogo) {
      throw new Error(`Logo image for ${logoData.url} could not be processed!`);
    }
    return {
      image: processedLogo,
      link: mapLink(logoData),
    };
  });

const useHeaderData = (locale: string): MultipleLogosHeaderProps => {
  const { allCsMultipleLogosSimpleHeader } = useStaticQuery<HeaderData>(headerQuery);

  const csHeader = allCsMultipleLogosSimpleHeader.nodes.find(
    (header) => header.locale === locale
  );
  if (!csHeader) {
    throw new Error(`Header for locale: ${locale} is missing!`);
  }

  return {
    logoLinks: processLogoLinks(csHeader.logos),
  };
};

export default useHeaderData;
