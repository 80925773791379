import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { SelectorContainer, SubheadingContainer } from '../styles';

export const StyledSubheadingContainer = styled(SubheadingContainer)`
  width: 90vw;
  max-width: none;
  ${mediaQuery.tabletLandscape`
    max-width: 544px;
  `}
`;

export const StyledSecondarySelectorContainer = styled(SelectorContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeadingContainer = styled(SelectorContainer)`
  ${mediaQuery.tabletPortrait`
    width: 30vw;
  `}
  margin-bottom: ${spacing(0.5)};
`;

export const StyledRadioInput = styled(RadioInput)`
  justify-content: center;
  min-height: 56px;

  ${RadioItem} {
    ${mediaQuery.tabletPortrait`
      max-width: 256px;
    `}
  }
`;
