import {
  CsSegmentSelector,
  SegmentContentType,
  SegmentSelectorData,
} from 'components/blocks/SegmentSelector/types';
import { nonFatalBuildError } from 'helpers/errorReporting';

export const extractSelectorData = (
  selector: CsSegmentSelector
): SegmentSelectorData | undefined => {
  let selectorData: SegmentSelectorData | undefined;
  Object.values(selector).forEach((value) => {
    if (value != null) {
      selectorData = value;
    }
  });
  return selectorData;
};

/* eslint-disable no-param-reassign */
const selectorTreeStort = (
  segmentSelectors: CsSegmentSelector[],
  parentSelector: CsSegmentSelector
): void => {
  const parentSelectorData = extractSelectorData(parentSelector);
  if (!parentSelectorData) {
    return;
  }

  // Get all possible children ids for that level
  const childrenContentIds = parentSelectorData.segment_contents.map(
    (option) => `${parentSelectorData.selector_unique_value}_${option.value}`
  );

  // Find all the children selectors with the associated parent ids
  const childrenSelectors = segmentSelectors.filter((selector) => {
    const selectorData = extractSelectorData(selector);
    return (
      selectorData &&
      selectorData.parent_identifier &&
      childrenContentIds.includes(selectorData.parent_identifier)
    );
  });

  // For each option of the parent selector, check if there is any continuation and assign it if it's the case
  parentSelectorData.segment_contents.forEach((segmentOption: SegmentContentType) => {
    const childSelector = childrenSelectors.find((section) => {
      const selectorData = extractSelectorData(section);
      return (
        selectorData &&
        selectorData.parent_identifier?.includes(`_${segmentOption.value}`)
      );
    });
    if (childSelector) {
      segmentOption.nextSelector = { ...childSelector };
      selectorTreeStort(segmentSelectors, segmentOption.nextSelector);
    }
  });
};

const mapSegmentSelectorsTree = (
  segmentSelectors: CsSegmentSelector[]
): CsSegmentSelector | null => {
  const originSelector = segmentSelectors.find((selector) => {
    const selectorData = extractSelectorData(selector);
    return selectorData && !selectorData.parent_identifier;
  });

  if (!originSelector) {
    nonFatalBuildError(
      'No origin selector found!',
      'Check if you have at most 1 selector without a parent identifier defined.'
    );
    return null;
  }

  selectorTreeStort(segmentSelectors, originSelector);
  return originSelector;
};

export default mapSegmentSelectorsTree;
