import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import InfoBanner from '@rsa-digital/evo-shared-components/components/InfoBanner';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql } from 'gatsby';
import { kebabCase } from 'lodash';
import React from 'react';
import ExitPageLayout from 'components/ExitPageLayout';
import SegmentSelector from 'components/blocks/SegmentSelector';
import { SegmentContainer } from 'components/blocks/SegmentSelector/styles';
import { CsSegmentSelector } from 'components/blocks/SegmentSelector/types';
import mapBlocks from 'helpers/blockMappers/mapBlocks';
import { CsBlock } from 'helpers/blockMappers/mappingUtils';
import { processInfoBannerData, processPageMeta } from 'helpers/csTypeProcessors';
import mapSegmentSelectorsTree from 'helpers/segmentSelectorMappers/mapSegmentSelectorsTree';
import { CsEntryNode, CsInfoBanner, PageMeta } from 'types/contentStack';

type Props = {
  data: TemplateJPageData;
};

const TemplateJPage: React.FC<Props> = ({ data }) => {
  const {
    locale,
    page_meta,
    url,
    info_banner,
    segment_selectors,
    blocks,
  } = data.csTemplateJ;
  const { meta } = processPageMeta(page_meta, url, data.allCsTemplateJ.nodes);
  const segmentSelectorsTree = mapSegmentSelectorsTree(segment_selectors);
  return (
    <ExitPageLayout pageMetadata={meta} locale={locale}>
      {info_banner?.body && (
        <GridContainer>
          <InfoBanner
            data-cy="InfoBanner"
            id={kebabCase(info_banner.heading.title)}
            {...processInfoBannerData(info_banner)}>
            <RichText html={info_banner.body} />
          </InfoBanner>
        </GridContainer>
      )}
      {segmentSelectorsTree !== null && <SegmentSelector data={segmentSelectorsTree} />}
      <SegmentContainer>{mapBlocks(blocks)}</SegmentContainer>
    </ExitPageLayout>
  );
};

export default TemplateJPage;

type TemplateJPageData = {
  csTemplateJ: {
    title: string;
    url: string;
    locale: string;
    info_banner?: CsInfoBanner;
    blocks: CsBlock[];
    segment_selectors: CsSegmentSelector[];
    page_meta: PageMeta;
  };
  allCsTemplateJ: {
    nodes: CsEntryNode[];
  };
};

export const query = graphql`
  query($id: String!, $uid: String!) {
    csTemplateJ(id: { eq: $id }) {
      title
      locale
      info_banner {
        heading {
          title
          icon {
            icon_code
          }
        }
        collapsible {
          show_text
          hide_text
          display_initially
        }
        body
      }
      blocks {
        accordion {
          ...AccordionBlockTemplateJPage
        }
        rich_text {
          ...RichTextBlock
        }
      }
      segment_selectors {
        dropdown_segment_selector {
          ...DropdownSegmentSelectorBlock
        }
        optional_secondary_segment_selector {
          ...OptionalSecondarySegnentSelectorBlock
        }
      }
      page_meta {
        ...PageMeta
      }
    }
    allCsTemplateJ(filter: { uid: { eq: $uid } }) {
      nodes {
        locale
        page_meta {
          canonical_tag
        }
      }
    }
  }
`;
