import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import React from 'react';
import LogosContainer from './LogosContainer';
import { StyledHeader } from './styles';
import useHeaderData from './useHeaderData';

export type MultipleLogosHeaderProps = {
  locale: string;
};

const MultipleLogosSimpleHeader: React.FC<MultipleLogosHeaderProps> = ({ locale }) => {
  const { logoLinks } = useHeaderData(locale);
  return (
    <StyledHeader data-cy="MultipleLogosSimpleHeader" as="header">
      <Grid>
        <GridItem>
          <LogosContainer logoLinks={logoLinks} shouldEagerLoad />
        </GridItem>
      </Grid>
    </StyledHeader>
  );
};

export default MultipleLogosSimpleHeader;
