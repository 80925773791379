import { SectionTitle } from '@rsa-digital/evo-shared-components/components/Accordion/styles';
import { ButtonStyle } from '@rsa-digital/evo-shared-components/components/Button/styles';
import {
  CtaBannerGridItem,
  CtaWrapper,
  Heading,
  TextWrapper,
} from '@rsa-digital/evo-shared-components/components/CtaBanner/styles';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import { fonts } from '@rsa-digital/evo-shared-components/theme/RSA/fonts';
import styled, { css } from 'styled-components';
import { RichTextStyle } from '../RichText/RichText/styles';

const flexVerticalCenter = css`
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const SegmentContainer = styled.div<{
  hasGreyBackground?: boolean;
  hasTransparentBackground?: boolean;
  noBorder?: boolean;
}>`
  padding: 0 25px;
  ${({ noBorder }) => !noBorder && `border-top: 1px solid ${colors.neutral04};`}
  background-color: ${({ hasGreyBackground, hasTransparentBackground }) => {
    const greyBackground = hasGreyBackground ? colors.neutral07 : colors.neutral08;
    return hasTransparentBackground ? 'transparent' : greyBackground;
  }};
  display: flex;
  ${flexVerticalCenter}
  width: 100vw;

  :empty {
    display: none;
  }

  ${mediaQuery.tabletLandscape`
    padding: 0 ${spacing(10)};
  `}

  > .actionCards {
    max-width: 90vw;
    margin-top: ${spacing(1.5)};
    margin-bottom: ${spacing(1.5)};
    ${mediaQuery.desktop`
      max-width: 1024px;
    `}
    &:first-child {
      margin-top: ${spacing(5)};
    }
    &:last-child, &:has(+.richText), &:has(+.ctaBanner) {
      margin-bottom: ${spacing(5)};
    }
  }

  // The Rich Text will be used as a declaration of a new section of the segment selector container.
  // Hence, it inherits these properties and removes the border if found as first child because of the overlap with the SegmentContainer's board.
  > .richText {
    border-top: 1px solid ${colors.neutral04};
    width: 100vw;
    padding-left: 5vw;
    padding-right: 5vw;
    ${mediaQuery.desktop`
      padding-right: ${spacing(44)};
      padding-left: ${spacing(44)};
    `}

    &:first-child {
      border-top: none;
      ${RichTextStyle} {
        margin-top: ${spacing(5)};
      }
    }

    &:last-child {
      ${RichTextStyle} {
        margin-bottom: ${spacing(5)};
      }
    }

    ${GridItem} {
      ${mediaQuery.desktop`
      width: 1024px;
    `}
    }

    ${RichTextStyle} {
      margin-top: ${spacing(1.5)};
      margin-bottom: ${spacing(1.5)};
    }
  }

  > .accordion {
    max-width: none;
    margin-top: ${spacing(1.5)};
    margin-bottom: ${spacing(1.5)};

    ${mediaQuery.tabletLandscape`
      width: 95vw;
    `}
    ${mediaQuery.desktop`
      width: 1240px;
    `}

    ${SectionTitle} {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;
    }
    
    &:first-child {
      margin-top: ${spacing(5)};
    }

    /* Because the bottom margin of the accordion goes a little bit over the new sections, it results in a cut of 4px, 
      which would practically made the distance between these elements of 36px. Hence, 4px have been added to the spacing
      to correct the issue. */
    &:last-child, &:has(+.richText), &:has(+.ctaBanner) {
      margin-bottom: ${spacing(5.75)};
    }
  }

  & > .ctaBanner {
    width: 100vw;
    border-top: solid 1px ${colors.neutral04};
    margin-top: -7px;
    margin-bottom: -7px;

    &:first-child {
      border-top: none;
    }
    ${CtaBannerGridItem} {
      border: 0;
        ${flexVerticalCenter}
        & > ${TextWrapper} {
          text-align: center;
          margin: ${spacing(2)} 0;
      
          ${Heading} {
            ${fonts.headingMedium};
          }
        }

        & > ${TextWrapper} {
          width: 100%;
          ${mediaQuery.tabletLandscape`
            width: 736px;
            `}
        }

        & > ${CtaWrapper} {
          width: 100%;
          ${flexVerticalCenter}
          margin: ${spacing(2)} 0;

          ${mediaQuery.tabletLandscape`
            flex-direction: row;
          `}
      
           ${ButtonStyle} {
              width: 90vw;
              ${mediaQuery.tabletLandscape`
                width: 270px;
              `}

              ${mediaQuery.tabletPortrait`
                margin-left: 0;
                margin-top: ${spacing(4)};
              `}
            }

            ${ButtonStyle}[data-variant="secondary"] {
              ${mediaQuery.tabletPortrait`
                  margin-left: ${spacing(2)};
              `}
            }
        }
    }
  }
`;
