import React from 'react';
import { Helmet } from 'react-helmet-async';

const recaptchaScriptSource = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`;
// Hack to workaround a discrepancy in the way React/Helmet renders the boolean properties on script tags
// During SSR it renders any truthy value as `async=""`
// During client rendering it renders as `async="true"`
//
// react-helmet will see this difference and update the script tag, which causes it to execute twice.
// by specifying the value as empty string on the client, we can avoid this re-execution.
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const booleanAttribute = (typeof window === 'undefined' ? true : '') as any;
// Load the recaptcha script and hide the default recaptcha badge.
// Instead of the badge, google policy and privacy data must be presented elsewhere in the journey.
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const Recaptcha: React.FC = () => {
  if (activeEnv === 'test') {
    return null;
  }

  return (
    <Helmet>
      <script
        key="recaptchaScript"
        src={recaptchaScriptSource}
        async={booleanAttribute}
        defer={booleanAttribute}
        className="optanon-category-C0002"
      />
      <style key="recaptchaStyle">{'.grecaptcha-badge { visibility: hidden; }'}</style>
    </Helmet>
  );
};
export default Recaptcha;
