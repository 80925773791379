import Dropdown from '@rsa-digital/evo-shared-components/components/Dropdown';
import { colors } from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import styled from 'styled-components';
import { SelectorContainer } from '../styles';

export const StyledDropdown = styled(Dropdown)`
  background-color: ${colors.neutral08};
`;

export const DropdownContainer = styled(SelectorContainer)`
  max-width: 544px;
`;
